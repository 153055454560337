import { breakpoints, debounce } from "./utilities.js";

const init = () => {

    resizeContainer();

    window.site.win.on('resize', debounce(resizeContainer))
}

function resizeContainer() {
    var padding = window.innerWidth < breakpoints.large ? '12px' : '30px';

    $(".alternate").each(function(x) {
        if (x % 2 === 0) {
            $($(".alternate")[x]).css('float', 'right').css('padding-left', padding);
        } else {
            $($(".alternate")[x]).css('float', 'left').css('padding-right', padding);
        }
    });
}

export default init;
